<template>
  <v-text-field
    ref="text"
    v-model="_title"
    dense
    outlined
    autofocus
    hide-details
    @blur="$emit('blur')"
    @keydown.enter="SET_CONFIRMED(true)"
  />
</template>

<style lang="scss" scoped>
.v-input.v-text-field::v-deep {
  .v-input__slot {
    padding-left: 8px !important;
    fieldset {
      left: -42px;
    }
  }
}
</style>

<script>
import { mapMutations } from "vuex";

export default {
  props: {
    title: {
      type: String,
      default: ""
    }
  },
  computed: {
    _title: {
      get() {
        return this.title;
      },
      set(title) {
        this.$emit("update:title", title);
      }
    }
  },
  methods: {
    ...mapMutations("flowDialog", ["SET_CONFIRMED"])
  }
};
</script>
